import React, { useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { api } from '../../api';
import { device } from '../../constants';

interface ContactUsForm {
  name: string;
  email: string;
  message: string;
}

type ContainerProps = {
  status: Boolean;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 60px;

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  flex: 0.7;
  width: 100%;
  margin: 10px auto;
  display: block;

  @media ${device.laptop} {
    flex: 1;
  }
`;

const DescriptionContainer = styled.div`
  flex: 0.3;
  width: 100%;
  display: block;
  margin: auto;

  @media ${device.laptop} {
    flex: 1;
  }
`;

const Form = styled.form`
  background-color: rgba(132, 146, 166, 0.1);
  max-width: 600px;
  display: block;
  margin: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  flex-wrap: wrap;
`;

const StyledInput = styled.input`
  flex: 1;
  height: 40px;
  border-radius: 4pt;
  border: 1pt solid rgb(229, 233, 242);
  padding: 2px 5px;
  margin: 10px;
`;

const StyledTextArea = styled.textarea`
  flex: 1;
  border-radius: 4pt;
  border: 1pt solid rgb(229, 233, 242);
  padding: 2px 5px;
  margin: 10px;
  font-family: 'Roboto', sans-serif;
`;

const SubmitButton = styled.button`
  border-radius: 50pt;
  border: none;
  font-size: 100%;
  color: #fff;
  background-color: rgb(255, 119, 119);
  margin: 10px;
  cursor: pointer;
  height: 30px;
  width: 75px;
  align-self: flex-end;
`;

const LoadingCircle = styled(FontAwesomeIcon)`
  align-self: center;
`;

const Title = styled.h1`
  font-size: 2em;
  font-weight: bold;
  margin: 20px auto;
  max-width: 600px;

  @media ${device.mobileM} {
    font-size: 11vw;
  }
`;

const Text = styled.p`
  font-size: 20px;
  margin: 20px 0;
`;

const ResponseText = styled.p<ContainerProps>`
  color: ${props => (props.status ? '#155724' : '#721c24')};
  padding-left: 15px;
`;

const MessageContainer = styled.div<ContainerProps>`
  width: 100%;
  max-width: 600px;
  background: ${props => (props.status ? '#d4edda' : '#f8d7da')};
  margin: 10px auto;
  padding: 10px 0;
`;

const ContactUsPage: React.FC = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState<Boolean>(false);
  const [responseMsg, setResponseMsg] = useState({ success: false, message: '' });
  const [disableForm, setDisableForm] = useState<boolean | undefined>(false);
  const [form, setForm] = useState<ContactUsForm>({
    name: '',
    email: '',
    message: '',
  });

  const handleOnChange = (
    e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>,
  ) => {
    setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
  };

  const clearForm = () => {
    setForm({ name: '', email: '', message: '' });
    setDisableForm(true);
  };
  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    try {
      await api.sendContactUsEmail(form);
      setResponseMsg({ success: true, message: 'Message delivered Successfully.' });
    } catch (err) {
      setResponseMsg({
        success: false,
        message: "Message couldn't be delivered, please try again later.",
      });
    }
    setLoading(false);
    clearForm();
    history.push(match.url);
  };

  return (
    <Container>
      <FormContainer>
        <Title>Get in touch with Globespinning</Title>
        <Form onSubmit={handleOnSubmit}>
          <Row>
            <StyledInput
              type="text"
              name="name"
              placeholder="Name"
              required
              disabled={disableForm}
              value={form.name}
              onChange={handleOnChange}
            />
            <StyledInput
              type="email"
              name="email"
              placeholder="Email Address"
              required
              disabled={disableForm}
              value={form.email}
              onChange={handleOnChange}
            />
          </Row>
          <Row>
            <StyledTextArea
              rows={5}
              name="message"
              placeholder="Message"
              required
              disabled={disableForm}
              value={form.message}
              onChange={handleOnChange}
            />
          </Row>
          <Row>
            <SubmitButton disabled={disableForm}>
              {loading ? <LoadingCircle icon={faCircleNotch} spin /> : 'submit'}
            </SubmitButton>
          </Row>
        </Form>
        {responseMsg.message ? (
          <MessageContainer status={responseMsg.success}>
            <ResponseText status={responseMsg.success}>{responseMsg.message}</ResponseText>
          </MessageContainer>
        ) : null}
      </FormContainer>
      <DescriptionContainer>
        <Text>We want to hear from you!</Text>
        <Text>
          Have a question or comment about Globespinning? Please send us an email or fill out our
          contact form. You are what makes Globespinning great!
        </Text>
      </DescriptionContainer>
    </Container>
  );
};

export default ContactUsPage;
