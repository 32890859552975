import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { topBarSize } from './constants';

import LandingPage from './Pages/LandingPage';
import TripsPage from './Pages/TripsPage';
import TripsDetailPage from './Pages/TripsDetailPage';
import ContactUsPage from './Pages/ContactUsPage';

import TopBar from './Components/TopBar';
import Footer from './Components/Footer';
import GoogleProvider from './Components/GoogleProvider';

const GlobalStyle = createGlobalStyle`
  #root {
    width: 100%;
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    color: rgb(31, 45, 61);
    font-weight: bold;
  }
`;

const Container = styled.div`
  padding-top: ${topBarSize.height + topBarSize.border}px;
  display: block;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
`;

const App: React.FC = () => {
  const GS_SERVERLESS_URL = CONFIG.GS_SERVERLESS_URL;

  return (
    <GoogleProvider>
      <GlobalStyle />
      <Router basename={CONFIG.BASE_NAME}>
        <TopBar />
        <Container>
          <Route path="/" exact component={LandingPage} />
          <Route path="/itineraries" exact component={TripsPage} />
          <Route path="/itineraries/:itineraryId" exact component={TripsDetailPage} />
          <Route
            path="/privacy-policy"
            render={() => {
              window.open(`${GS_SERVERLESS_URL}/privacy-policy`, '_blank');
              return null;
            }}
          />
          <Route
            path="/terms-of-service"
            render={() => {
              window.open(`${GS_SERVERLESS_URL}/terms-of-service`, '_blank');
              return null;
            }}
          />
          {/* <Route path="/terms-of-service" exact component={ToSPage} />
          <Route path="/privacy-policy" exact component={PrivacyPolicyPage} /> */}
          <Route path="/contact-us" exact component={ContactUsPage} />
        </Container>
        <Footer />
      </Router>
    </GoogleProvider>
  );
};

export default App;
