import { useState, useEffect, useCallback } from 'react';

const useWindowSize = () => {
  const getSize = useCallback(() => {
    return {
      width: window.innerWidth || undefined,
    };
  }, []);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSize]);

  return windowSize;
};

export default useWindowSize;
