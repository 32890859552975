import React from 'react';

interface GoogleContextValues {
  isInitialized: boolean;
}

const initialValue: GoogleContextValues = {
  isInitialized: false,
};

const GoogleContext = React.createContext(initialValue);

export default GoogleContext;
