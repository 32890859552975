import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TripCard from './TripCard';
import { api } from '../api';
import { useHistory } from 'react-router';

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
`;

const LandingTrips: React.FC = () => {
  const [itineraries, setItineraries] = useState<Array<Itinerary>>([]);
  const history = useHistory();

  useEffect(() => {
    const findPage = async () => {
      const page = await api.getItineraryPage(1, 3, true);
      setItineraries(page.results);
    };

    findPage();
  }, []);

  const showItineraries = itineraries.map((itinerary, index) => {
    const handleClick = () => {
      return history.push(`/itineraries/${itinerary.id}`);
    };
    return <TripCard key={index} itinerary={itinerary} loading={false} onClick={handleClick} />;
  });

  return <CardContainer>{showItineraries}</CardContainer>;
};

export default LandingTrips;
