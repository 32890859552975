import React from 'react';
import Styled from 'styled-components';

interface Props {
  lat: number;
  lng: number;
  text: string;
  iconSrc: string;
  isActive: boolean;
}

const Icon = Styled.img`
  z-index: 2;
  width: 30px;
  height: 40px;
  position: absolute;
  left: -15px;
  bottom: 0;
`;

const DetailBubble = Styled.div`
  background: #fff;
  font-size: 20px;
  border-radius: 5px;
  white-space: nowrap;
  padding: 2px 4px;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
  z-index: 999;
  text-align: center;
  display: inline-block;
  position: absolute;
  transform: translate(-50%, -277%);

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #fff;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
  }
`;

const Marker: React.FC<Props> = props => {
  const { iconSrc, isActive, text } = props;

  return (
    <React.Fragment>
      {isActive ? (
        <DetailBubble>
          <p>{text}</p>
        </DetailBubble>
      ) : null}
      <Icon src={iconSrc} />
    </React.Fragment>
  );
};

export default Marker;
