export const topBarSize = {
  height: 100,
  border: 3,
};

export const footerSize = {
  height: 100,
  border: 3,
};

const size = {
  mobileS: '300px',
  mobileM: '476px',
  mobileL: '600px',
  tablet: '800px',
  laptop: '1000px',
  desktop: '1164px',
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  desktop: `(max-width: ${size.desktop})`,
};

export const links = {
  playStore: 'https://play.google.com/store/apps/details?id=com.globespinning.app',
  appleStore: 'https://apps.apple.com/us/app/globespinning-travel-journals/id954024249?mt=8',
  twitter: 'https://twitter.com/globespinning',
  facebook: 'https://www.facebook.com/Globespinning',
  instagram: 'https://www.instagram.com/globespinning/',
  youtube: 'https://www.youtube.com/channel/UCJl3twuJ_sf3XEGdPoXyEUw',
  pinterest: 'https://pinterest.com/globespinning/',
};
