import { useEffect, useState } from 'react';

const useScript = (url: string) => {
  const [loading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.onload = () => setIsLoading(false);

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return loading;
};

export default useScript;
