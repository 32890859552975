import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import logoPng from '../assets/images/logo.png';
import { topBarSize, device } from '../constants';
import useWindowSize from '../hooks/useWindowSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import posed from 'react-pose';

const Container = styled.div`
  z-index: 999;
  height: ${topBarSize.height}px;
  width: 100%;
  border-bottom: ${topBarSize.border}px solid rgb(229, 233, 242);
  display: flex;
  position: fixed;
  background-color: #fff;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  max-width: 1600px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;

  @media ${device.desktop} {
    padding: 0 20px;
  }
`;

const Image = styled.img`
  width: 100%;
  margin: auto;
  max-width: 400px;
`;

const Navigator = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const link = css`
  color: rgb(31, 45, 61);
  text-decoration: none;
  padding: 42px 25px;
  border-bottom: 3px solid transparent;
`;

const StyledLink = styled(Link)`
  ${link}
  :hover {
    border-color: rgb(255, 119, 119);
  }
`;

const Button = styled.button`
  border: 1px solid black;
  border-radius: 10px;
  background: none;
  margin-left: 10px;

  :focus {
    outline: none;
  }
`;

const MenuAnimation = posed.div({
  closed: { height: 0 },
  open: { height: 40 },
});

const Menu = styled(MenuAnimation)`
  overflow: hidden;
  position: absolute;
  top: 103px;
  right: 0px;
  width: 100%;
  text-align: right;
  background: #ffffff;
  box-shadow: 0px 5px 10px 0px rgba(158, 158, 158, 1);
`;

// const LoginButton = styled(Link)`
//   ${link}
//   border: 2px solid rgb(255, 119, 119);
//   padding: 3px 15px;
//   border-radius: 67px;
// `;

const TopBar: React.FC = () => {
  const [menu, setMenu] = useState<boolean>(false);
  const size = useWindowSize();
  const history = useHistory();

  useEffect(() => {
    setMenu(false);
  }, [history.location]);

  const handleMenu = () => {
    setMenu(!menu);
  };

  const isMobile = size.width && size.width < 900;
  const MenuWrapper = isMobile ? Menu : React.Fragment;
  const menuProps = isMobile ? { pose: menu ? 'open' : 'closed' } : {};

  return (
    <Container>
      <Header>
        <Link to="/">
          <Image src={logoPng} />
        </Link>
        <MenuWrapper {...menuProps}>
          <Navigator>
            <StyledLink to="/">Home</StyledLink>
            <StyledLink to="/itineraries">Trips</StyledLink>
            {/* <StyledLink to="/">Blog</StyledLink>
           <LoginButton to="/">Login</LoginButton> */}
          </Navigator>
        </MenuWrapper>
        {isMobile ? (
          <Button onClick={handleMenu}>
            <FontAwesomeIcon icon={faBars} size={size.width && size.width > 300 ? '3x' : '2x'} />
          </Button>
        ) : null}
      </Header>
    </Container>
  );
};

export default TopBar;
