import React, { useState } from 'react';
import styled from 'styled-components';
import loadingImg from '../assets/images/image-loader.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

interface Props {
  itinerary: Itinerary;
  loading: boolean;
  onClick: () => void;
}

const Card = styled.div`
  width: 320px;
  margin: 20px;
  cursor: pointer;
`;

const CardHeader = styled.div`
  margin: 10px;
  min-height: 55px;
`;

const LocationTitle = styled.p`
  font-size: 1rem;
  line-height: 14.7pt;
`;

const ItineraryName = styled.p`
  font-size: 0.9rem;
  font-weight: lighter;
  color: rgb(132, 146, 166);
  letter-spacing: -0.15pt;
  line-height: 13.3pt;
`;

const CardImg = styled.img`
  width: 100%;
  background: grey;
`;

const OwnerImg = styled.img`
  border-radius: 50%;
  width: 30px;
  height: 30px;
`;

const CardFooter = styled.div`
  margin: 10px;
  display: flex;
  overflow: hidden;
`;

const CardInfo = styled.div`
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const OwnerName = styled.p`
  font-size: 0.9rem;
  letter-spacing: -0.15pt;
  line-height: 13.3pt;
  font-weight: 500;
`;

const OwnerStats = styled.p`
  font-size: 0.7rem;
  line-height: 8.7pt;
  font-weight: lighter;
  letter-spacing: 0.05pt;
  color: rgb(132, 146, 166);
`;

const LoadingWrapper = styled.div`
  position: absolute;
  width: 350px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TripCard: React.FC<Props> = props => {
  const { itinerary, onClick, loading } = props;
  const [imgLoading, setImgLoading] = useState<boolean>(true);

  const handleImgError = (event: any): void => {
    event.target.src = loadingImg;
  };

  const handleImgLoad = () => {
    setImgLoading(false);
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <FontAwesomeIcon icon={faCircleNotch} size="3x" spin />
      </LoadingWrapper>
    );
  }

  return (
    <Card onClick={onClick}>
      <CardHeader>
        <LocationTitle>{itinerary.place.name}</LocationTitle>
        <ItineraryName>{itinerary.name}</ItineraryName>
      </CardHeader>
      {imgLoading ? <CardImg src={loadingImg} /> : null}
      <CardImg
        src={itinerary.cover_picture || ''}
        onLoad={handleImgLoad}
        onError={handleImgError}
      />
      <CardFooter>
        <OwnerImg
          src={itinerary.owner.picture ? itinerary.owner.picture.url : ''}
          onError={handleImgError}
        />
        <CardInfo>
          <OwnerName>
            {itinerary.owner.first_name} {itinerary.owner.last_name}
          </OwnerName>
          <OwnerStats>
            {itinerary.stats ? itinerary.stats.followers : 0} bookmarks,&nbsp;
            {itinerary.stats ? itinerary.stats.comments : 0} comments
          </OwnerStats>
        </CardInfo>
      </CardFooter>
    </Card>
  );
};

export default TripCard;
