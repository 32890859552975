import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { api } from '../../api';
import { device } from '../../constants';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import TripCard from '../../Components/TripCard';
import StoreButtons from '../../Components/StoreButtons';
import SearchBar from '../../Components/SearchBar';

const Trips = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SearchWrapper = styled.div`
  margin: 10px 50px 50px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    margin: auto;
  }

  @media ${device.mobileL} {
    flex-direction: column;
    margin: 0;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const LoadingCircle = styled(FontAwesomeIcon)`
  align-self: center;
`;

const NoItineraryFound = styled.div`
  align-self: center;
`;

const TripsPage: React.FC = () => {
  const [itineraries, setItineraries] = useState<Array<Itinerary>>([]);
  const [page, setPage] = useState<number>(1);
  const refContainer = useRef<HTMLDivElement>(null);
  const [searchResults, setSearchResults] = useState<SearchResult>();
  const [disableScroll, setDisableScroll] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const history = useHistory();

  const changePage = () => setPage(page + 1);
  const [loading, setLoading] = useInfiniteScroll(changePage, refContainer.current, disableScroll);

  const fetchApi = async () => {
    if (page !== 1) setLoading(true);
    setFetching(true);
    const response = await api.list(page, searchResults);
    setItineraries([...itineraries, ...response.results]);
    !!response.next ? setDisableScroll(false) : setDisableScroll(true);
    setFetching(false);
    if (page !== 1) setLoading(false);
  };

  useEffect(() => {
    fetchApi();
  }, [page, searchResults]);

  const handleOnSelect = (searchResult: SearchResult) => {
    setItineraries([]);
    setSearchResults(searchResult);
    setPage(1);
  };

  const handleOnClear = () => {
    setItineraries([]);
    setSearchResults({ city: '', country: '', state: '' });
    setPage(1);
  };

  if (!itineraries) return null;

  const cards = itineraries.map((itinerary, index) => {
    const handleClick = () => {
      return history.push(`/itineraries/${itinerary.id}`);
    };

    return <TripCard key={index} itinerary={itinerary} onClick={handleClick} loading={loading} />;
  });

  return (
    <Trips ref={refContainer}>
      <SearchWrapper>
        <StoreButtons />
        <SearchBar onSelect={handleOnSelect} onClear={handleOnClear} />
      </SearchWrapper>
      {cards.length !== 0 && <CardsWrapper>{cards}</CardsWrapper>}
      {!fetching && cards.length === 0 && <NoItineraryFound>no itinerary found</NoItineraryFound>}
      {fetching && <LoadingCircle icon={faCircleNotch} size="5x" spin />}
    </Trips>
  );
};

export default TripsPage;
