import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import backgroundPng from '../../assets/images/Home/background.png';
import previewPng from '../../assets/images/Home/preview.png';
import StoreButtons from '../../Components/StoreButtons';
import LandingTrips from '../../Components/LandingTrips';
import Share from '../../Components/Share';
import { device } from '../../constants';
import Tutorial from '../../Components/Tutorial';

// Preview styles
const PreviewBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'title image';
  padding: 50px 0;
  align-items: center;
  justify-items: center;
  margin: auto;

  @media ${device.desktop} {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: title;
  background-image: url(${backgroundPng});
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;

  @media ${device.desktop} {
    background-position: center;
    margin: 20px 0 80px 0;
  }
`;

const Title = styled.h1`
  font-size: 3.5em;
  font-weight: bold;
  white-space: nowrap;

  @media ${device.mobileM} {
    font-size: 11vw;
  }
`;

const PreviewImg = styled.img`
  width: 500px;
  min-width: 460px;

  @media ${device.desktop} {
    margin-bottom: 20px;
  }

  @media ${device.mobileM} {
    width: 90%;
    min-width: auto;
  }
`;

const Box = styled.div`
  margin-top: 20px;
`;

// Trip styles
const TripsContainer = styled.div`
  background-color: rgba(132, 146, 166, 0.1);
  padding: 80px 0;
  width: 100%;
  display: block;
`;

const TripsBox = styled.div`
  padding: 0 50px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

const ViewMore = styled(Link)`
  text-decoration: none;
  align-self: flex-end;
  color: rgb(255, 119, 119);

  @media ${device.mobileL} {
    margin-top: 15px;
    align-self: center;
  }
`;

// Tutorial styles
const TutorialBox = styled.div`
  padding: 50px 0;
  width: 100%;
  margin: auto;
`;

const LandingPage: React.FC = () => {
  return (
    <React.Fragment>
      <PreviewBox>
        <TitleWrapper>
          <Title>
            Create and share <br />
            trip itineraries with <br />
            Globespinning
          </Title>
          <Box>
            <StoreButtons />
          </Box>
        </TitleWrapper>
        <PreviewImg src={previewPng} />
      </PreviewBox>
      <TripsContainer>
        <TripsBox>
          <Title>Discover Trips</Title>
          <LandingTrips />
          <ViewMore to="/itineraries">View More Trips</ViewMore>
        </TripsBox>
      </TripsContainer>
      <TutorialBox>
        <Tutorial />
        <Share />
      </TutorialBox>
    </React.Fragment>
  );
};

export default LandingPage;
