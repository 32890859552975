import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
  faPinterestP,
} from '@fortawesome/free-brands-svg-icons';
import { links, device } from '../constants';

const Container = styled.div`
  margin-top: 150px;
  text-align: center;
`;

const Label = styled.h2`
  font-size: 3.5em;
  font-weight: bold;

  @media ${device.mobileM} {
    font-size: 12vw;
  }
`;

const Button = styled.a`
  text-decoration: none;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  background: none;
  border: 1px solid rgb(255, 119, 119);
  border-radius: 90%;
  padding: 10px;
  color: rgb(255, 119, 119);
`;

const ShareIcons = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const socials = [
  {
    link: links.twitter,
    icon: faTwitter,
  },
  {
    link: links.facebook,
    icon: faFacebookF,
  },
  {
    link: links.instagram,
    icon: faInstagram,
  },
  {
    link: links.youtube,
    icon: faYoutube,
  },
  {
    link: links.pinterest,
    icon: faPinterestP,
  },
];

const buttons = socials.map((social, index) => {
  return (
    <Button key={index} href={social.link} target="_blank">
      <FontAwesomeIcon icon={social.icon} />
    </Button>
  );
});

const Share: React.FC = () => {
  return (
    <Container>
      <Label>Follow us!</Label>
      <ShareIcons>{buttons}</ShareIcons>
    </Container>
  );
};

export default Share;
