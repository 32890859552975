import React from 'react';
import googlePlay from '../assets/images/googleplay.png';
import iTunesAppStore from '../assets/images/itunesappstore.png';
import styled from 'styled-components';
import { links, device } from '../constants';

const Container = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;

  @media ${device.mobileS} {
    flex-direction: column;
  }
`;

const Image = styled.img`
  margin-right: 10px;
  width: 150px;
  height: 50px;

  @media ${device.mobileS} {
    margin: 5px 0;
  }
`;

const StoreButtons: React.FC = () => {
  return (
    <Container>
      <a href={links.playStore} target="_blank">
        <Image src={googlePlay} alt="GooglePlay" />
      </a>
      <a href={links.appleStore} target="_blank">
        <Image src={iTunesAppStore} alt="GooglePlay" />
      </a>
    </Container>
  );
};

export default StoreButtons;
