import React from 'react';
import useScript from '../hooks/useScript';
import GoogleContext from './GoogleContext';

const GoogleProvider: React.FC = props => {
  const loading = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${CONFIG.GOOGLE_API_KEY}&libraries=places`,
  );

  return (
    <GoogleContext.Provider value={{ isInitialized: !loading }}>
      {props.children}
    </GoogleContext.Provider>
  );
};

export default GoogleProvider;
