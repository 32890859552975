import React from 'react';
import styled from 'styled-components';
import Postcard from './Postcard';

interface Props {
  postcards: Array<Postcard>;
  day: number;
}

const Day = styled.p`
  color: rgb(132, 146, 166);
  font-weight: lighter;
  padding: 10px;
  border-bottom: 1px solid rgb(132, 146, 166);
`;

const TripByDay: React.FC<Props> = props => {
  const { day, postcards } = props;

  const postcardByDay = postcards.map((postcard, index) => {
    return <Postcard key={index} postcard={postcard} />;
  });

  return (
    <React.Fragment>
      <Day>Day {day}</Day>
      {postcardByDay}
    </React.Fragment>
  );
};

export default TripByDay;
