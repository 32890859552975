import React from 'react';

interface PostcardContextValues {
  onPostcardSelect: (postcard: Postcard) => void;
  selectedPostCard: Postcard | undefined;
  postcardRef: any;
}

const initialValue: PostcardContextValues = {
  onPostcardSelect: () => null,
  selectedPostCard: undefined,
  postcardRef: null,
};

const PostcardContext = React.createContext(initialValue);

export default PostcardContext;
