import React, { useState, useContext } from 'react';
import Styled from 'styled-components';
import loadingImg from '../assets/images/image-loader.png';
import PostcardContext from '../Pages/TripsDetailPage/PostcardContext';
import { device } from '../constants';

interface Props {
  postcard: Postcard;
}

interface ContainerProps {
  isSelected: boolean;
}

const Container = Styled.div<ContainerProps>`
  display: flex;
  align-content: center;
  max-height: 300px;
  background: ${props => (props.isSelected ? 'rgba(0,0,0,0.1)' : '#fff')};

  @media ${device.mobileM} {
    flex-direction: column;
    align-items: center;
    max-height: fit-content;
  }
`;

const PostcardImg = Styled.img`
  width: 250px;
`;

const PostcardData = Styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LightText = Styled.p`
  font-size: 13px;
  font-weight: lighter;
  color: rgb(132,146,166);
  margin-top: 10px;
`;

const Description = Styled.p`
  font-weight: 400;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-height: 16px;
  max-height: 64px;
`;

// const BookNow = Styled.button`
//   border: 1pt solid rgb(39, 52, 68);
//   border-radius: 8pt;
//   padding: 10px;
//   outline: none;
// `;

const Postcard: React.FC<Props> = props => {
  const { postcard } = props;
  const [imgLoading, setImgLoading] = useState<boolean>(true);
  const context = useContext(PostcardContext);
  const selectedPostcard = context.selectedPostCard;

  const handleOnClick = () => {
    context.onPostcardSelect(postcard);
    if (context.postcardRef.current) {
      context.postcardRef.current.focus();
    }
  };

  const handleImgLoad = () => {
    setImgLoading(false);
  };

  if (!postcard.picture) return null;

  return (
    <Container isSelected={selectedPostcard === postcard} onClick={handleOnClick}>
      {imgLoading ? <PostcardImg src={loadingImg} onLoad={handleImgLoad} /> : null}
      <PostcardImg src={postcard.picture.url} onLoad={handleImgLoad} />
      <PostcardData>
        <p>{postcard.place.name}</p>
        <LightText>{postcard.address}</LightText>
        <Description>{postcard.description}</Description>
        {/* <LightText>{postcard.priority}</LightText>
        <Description>
          This is text entered about the location. Users are able to enter a specific amount of text
          to help give more information about what to do at that location. Text field is max 4
          lines. This is text entered about the location. Users are able to enter a specific amount
          of text to help give more information about what to do at that location. Text field is max
          4 lines.
        </Description>
        <BookNow>book now</BookNow> */}
      </PostcardData>
    </Container>
  );
};

export default Postcard;
