import React from 'react';
import styled from 'styled-components';
import { device } from '../constants';
import gsTutorialDiscover from '../assets/images/tutorial/gs-tutorial-1.png';
import gsTutorialShare from '../assets/images/tutorial/gs-tutorial-2.png';
import gsTutorialCreate from '../assets/images/tutorial/gs-tutorial-3.png';

const TutorialBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  :nth-child(even) {
    flex-direction: row-reverse;

    @media ${device.mobileL} {
      flex-direction: column;
    }
  }

  @media ${device.mobileL} {
    flex-direction: column;
    text-align: center;
  }
`;

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;

  @media ${device.mobileL} {
    margin: 30px 0;
  }
`;

const Title = styled.p`
  font-size: 35px;
  margin-bottom: 20px;
  font-weight: bolder;
`;

const Description = styled.p`
  font-size: 22px;
  color: #666666;
  font-weight: lighter;
`;

const TutorialImg = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 10px 0;
`;

const Tutorial: React.FC = () => {
  const tutoSteps = [
    {
      title: 'Discover great new trips',
      description: 'Explore and save great trip itineraries to help plan your next adventure',
      image: gsTutorialDiscover,
    },
    {
      title: 'Create beautiful trip itineraries',
      description: 'Easily create post-trip itineraries from pictures in your camera roll',
      image: gsTutorialCreate,
    },
    {
      title: 'Share your trips with friends',
      description: 'Share bookable trip itineraries with your friends and followers',
      image: gsTutorialShare,
    },
  ];

  const tutorial = tutoSteps.map((step, index) => {
    return (
      <TutorialBox key={index}>
        <DescriptionBox>
          <Title>{step.title}</Title>
          <Description>{step.description}</Description>
        </DescriptionBox>
        <TutorialImg src={step.image} />
      </TutorialBox>
    );
  });
  return <React.Fragment>{tutorial}</React.Fragment>;
};

export default Tutorial;
