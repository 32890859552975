const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'X-GS-DeviceUserAgent': `${CONFIG.GS_DEVICE_USER_AGENT}`,
    'X-GS-DeviceEnv': `${CONFIG.GS_DEVICE_ENV}`,
    'X-GS-DeviceUID': `${CONFIG.GS_DEVICE_UID}`,
  };
};

type methods = 'GET' | 'POST' | 'PUT';

const request = async (method: methods, path: string, data?: any) => {
  let url = `${CONFIG.API_URL}${path}`;
  const options: RequestInit = {
    method: method,
    headers: getHeaders(),
  };

  if (data) {
    if (method !== 'GET') {
      options.body = JSON.stringify(data);
    } else {
      url += '?' + new URLSearchParams(data);
    }
  }

  const res = await fetch(url, options);
  if (res.status >= 200 && res.status < 300) {
    return res.status === 204 ? null : res.json();
  } else {
    const err = await res.json();
    throw new Error(err);
  }
};

class Api {
  getItineraries = async () => {
    const response = await request('GET', '/itineraries/');
    return response;
  };

  getItineraryPage = async (
    pageNumber: number,
    perPage: number = 10,
    isDiscover: boolean = true,
  ) => {
    const response = await request('GET', '/itineraries/', {
      page: pageNumber,
      per_page: perPage,
      discover: isDiscover,
    });
    return response;
  };

  getItinerary = async (itineraryId: string) => {
    const response = await request('GET', `/itineraries/${itineraryId}/`);
    return response;
  };

  list = async (page: number, place?: SearchResult | null) => {
    const perPage = 10;
    const url = place ? '/itineraries/published/' : '/itineraries/';
    let params = {
      page: page,
      per_page: perPage,
      discover: true,
    };

    if (place) {
      params = {
        ...params,
        country: place.country,
        state: place.state,
        city: place.city,
        discover: false,
      };
    }

    const response = await request('GET', url, params);
    return response;
  };

  sendContactUsEmail = async (contactUsData: ContactUsData) => {
    const response = await request('POST', '/contact-us/', contactUsData);
    return response;
  };
}

export const api = new Api();
