import React, { useState, useContext } from 'react';
import Autocomplete from 'react-google-autocomplete';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import GoogleContext from './GoogleContext';

interface SearchBarProps {
  onSelect: (results: SearchResult) => void;
  onClear: () => void;
}

const SearchWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  height: 50px;
  border-radius: 40px;
  border: solid 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
`;

const SearchInput = styled(Autocomplete)`
  width: 100%;
  max-width: 500px;
  height: 30px;
  font-size: 20px;
  outline: none;
  border: none;

  ::placeholder {
    font-style: italic;
  }
`;

const CloseIcon = styled(FontAwesomeIcon)`
  right: 70px;
`;

const SearchBar: React.FC<SearchBarProps> = props => {
  const { onSelect, onClear } = props;
  const googleContext = useContext(GoogleContext);
  const [searchValue, setSearchValue] = useState<string>();

  const handleOnSelect = ({ formatted_address, address_components }) => {
    const placeSearch = {
      country: '',
      city: '',
      state: '',
    };

    const countryObject = address_components.find(c => c.types.includes('country'));
    if (countryObject) {
      placeSearch.country = countryObject.short_name;
    }

    const cityObject = address_components.find(c => c.types.includes('locality'));
    if (cityObject) {
      placeSearch.city = cityObject.short_name;
    }

    const stateObject = address_components.find(c =>
      c.types.includes('administrative_area_level_1'),
    );
    if (stateObject) {
      placeSearch.state = stateObject.short_name;
    }

    setSearchValue(formatted_address);
    onSelect(placeSearch);
  };

  const handleOnClear = () => {
    setSearchValue('');
    onClear();
  };

  const handleOnChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  if (!googleContext.isInitialized) return null;

  return (
    <SearchWrapper>
      <SearchInput
        placeholder="Search by location"
        types={['(regions)']}
        onPlaceSelected={handleOnSelect}
        value={searchValue}
        onChange={handleOnChange}
      />
      <CloseIcon icon={faTimesCircle} size="2x" onClick={handleOnClear} />
    </SearchWrapper>
  );
};

export default SearchBar;
