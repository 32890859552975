import 'react-hot-loader';
import 'babel-polyfill';

import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import App from './App';

import reactDom from 'react-dom';
import 'reset-css';

const AppContainer = () => {
  return <App />
};

const AppWithHotLoader = hot(AppContainer);

reactDom.render(<AppWithHotLoader />, document.getElementById('root'));
