import React, { useState, useEffect } from 'react';
import Marker from './Marker';

interface Props {
  postcard: Postcard;
  lat: number;
  lng: number;
  isActive: boolean;
}

const getMarkerIcon = async (postcard: Postcard): Promise<string> => {
  const stamps = postcard.stamps[0];
  const priority = postcard.priority ? `-${postcard.priority}` : '';
  const image = await import(
    /* webpackMode: "eager" */ `../assets/images/map-markers/pin-${stamps}${priority}.png`
  );
  return image.default;
};

const PostcardMarker: React.FC<Props> = props => {
  const { postcard, lat, lng, isActive } = props;
  const [icon, setIcon] = useState<string>();

  useEffect(() => {
    getMarkerIcon(postcard).then(icon => {
      setIcon(icon);
    });
  }, []);

  if (!icon) return null;

  return (
    <Marker lat={lat} lng={lng} text={postcard.place.name} iconSrc={icon} isActive={isActive} />
  );
};

export default PostcardMarker;
