import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact, { Coords } from 'google-map-react';
import styled from 'styled-components';

interface Props {
  mapCenter: Coords;
}

const Container = styled.div`
  width: 100%;
`;

const Map: React.FC<Props> = props => {
  const { mapCenter } = props;
  const zoom: number = 10;
  const mapRef = useRef(null);
  const [center, setCenter] = useState<Coords>(mapCenter);

  useEffect(() => {
    setCenter(mapCenter);
  }, [mapCenter]);

  const handleDragEnd = () => {
    const map = (mapRef.current as any).map_;
    const center = map.getCenter();
    setCenter({ lat: center.lat(), lng: center.lng() });
  };

  return (
    <Container>
      <GoogleMapReact
        bootstrapURLKeys={{ key: CONFIG.GOOGLE_API_KEY }}
        center={center}
        defaultZoom={zoom}
        onDragEnd={handleDragEnd}
        ref={mapRef}
      >
        {props.children}
      </GoogleMapReact>
    </Container>
  );
};

export default Map;
