import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Coords } from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import PostcardContext from './PostcardContext';
import { api } from '../../api';
import { device } from '../../constants';
import TripByDay from '../../Components/TripByDay';
import Map from '../../Components/Map';
import PostcardMarker from '../../Components/PostcardMarker';

const Container = styled.div`
  overflow: hidden;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'map map map trip trip trip trip';

  @media ${device.mobileL} {
    display: flex;
    flex-direction: column;
  }
`;

const MapWrapper = styled.div`
  grid-area: map;
  width: 100%;
  height: 500px;
  background: palevioletred;

  :focus {
    outline: none;
  }
`;

const TripWrapper = styled.div`
  grid-area: trip;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TripHeader = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgb(132, 146, 166);
`;

const ItineraryPlace = styled.p`
  font-size: 25px;
  line-height: 41pt;
  letter-spacing: 0.26pt;
  font-weight: normal;
`;

const ItineraryName = styled.p`
  color: rgba(60, 60, 67, 0.3);
  font-size: 20px;
  letter-spacing: 0.22pt;
  font-weight: lighter;
`;

const OwnerImg = styled.img`
  border-radius: 50%;
  width: 70px;
  margin-right: 10px;
`;

const OwnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

const OwnerColumn = styled(Column)`
  flex: 0.3;
`;

const LocationColumn = styled(Column)`
  flex: 0.7;
`;

const TripBody = styled.div`
  max-height: 900px;
  overflow: auto;
`;

const GoBackContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding-left: 15px;
  font-size: 20px;
  font-weight: lighter;
  background-color: rgb(255, 119, 119);
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

const GoBackIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const LoadingCircle = styled(FontAwesomeIcon)`
  align-self: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BookNowButton = styled.a`
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  align-self: flex-end;
  margin: 10px 20px;
  width: 100%;
  max-width: 180px;
  background-color: rgb(255, 119, 119);
  border-radius: 5px;
  padding: 10px 30px;
  color: #fff;
  font-weight: lighter;
`;

const TripsDetailPage = () => {
  const [itinerary, setItinerary] = useState<Itinerary>();
  const [loading, setLoading] = useState<boolean>(true);
  const [postcards, setPostcards] = useState<Array<Postcard>>([]);
  const [days, setDays] = useState<Array<Day>>();
  const [selectedPostcard, setSelectedPostcard] = useState<Postcard>();
  const [mapCenter, setMapCenter] = useState<Coords>();
  const mapRef = useRef(null);
  const { itineraryId } = useParams();

  useEffect(() => {
    const fetchItinerary = async (itineraryId: string) => {
      const itinerary: Itinerary = await api.getItinerary(itineraryId);
      setPostcards(
        itinerary.days.reduce((postcards: Array<Postcard>, day) => {
          return [...postcards, ...day.postcards];
        }, []),
      );

      setDays(itinerary.days);
      setMapCenter({ lat: itinerary.place.location[0], lng: itinerary.place.location[1] });
      setItinerary(itinerary);
      setLoading(false);
    };

    if (itineraryId) fetchItinerary(itineraryId);
  }, []);

  if (loading || !itinerary || !mapCenter || !days) {
    return (
      <LoadingContainer>
        <LoadingCircle icon={faCircleNotch} size="5x" spin />
        <p>Loading Itinerary...</p>
      </LoadingContainer>
    );
  }

  const handleSelectedPostcard = (postcard: Postcard) => {
    setSelectedPostcard(postcard);
    setMapCenter({ lat: postcard.place.location[0], lng: postcard.place.location[1] });
  };

  const trips = days.map((day, index) => {
    return <TripByDay key={index} day={index + 1} postcards={day.postcards} />;
  });

  const markers = postcards.map((postcard, index) => {
    return (
      <PostcardMarker
        key={index}
        lat={postcard.place.location[0]}
        lng={postcard.place.location[1]}
        postcard={postcard}
        isActive={selectedPostcard === postcard}
      />
    );
  });

  return (
    <PostcardContext.Provider
      value={{
        onPostcardSelect: handleSelectedPostcard,
        selectedPostCard: selectedPostcard,
        postcardRef: mapRef,
      }}
    >
      <GoBackContainer>
        <StyledLink to="/itineraries">
          <GoBackIcon icon={faChevronLeft} />
          Discover Trips
        </StyledLink>
      </GoBackContainer>
      <Container>
        <MapWrapper ref={mapRef} tabIndex={1}>
          <Map mapCenter={mapCenter}>{markers}</Map>
        </MapWrapper>
        <TripWrapper>
          {itinerary.booking_active && (
            <BookNowButton href={itinerary.booking_url} target="_blank">
              Book now
            </BookNowButton>
          )}
          <TripHeader>
            <LocationColumn>
              <ItineraryPlace>{itinerary.place.name}</ItineraryPlace>
              <ItineraryName>{itinerary.name}</ItineraryName>
            </LocationColumn>
            <OwnerColumn>
              <OwnerWrapper>
                <OwnerImg src={itinerary.owner.picture ? itinerary.owner.picture.url : ''} />
                <p>
                  {itinerary.owner.first_name} {itinerary.owner.last_name}
                </p>
              </OwnerWrapper>
            </OwnerColumn>
          </TripHeader>
          <TripBody>{trips}</TripBody>
        </TripWrapper>
      </Container>
    </PostcardContext.Provider>
  );
};

export default TripsDetailPage;
