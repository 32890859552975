import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import StoreButtons from '../Components/StoreButtons';
import { footerSize, device } from '../constants';

const FooterContainer = styled.footer`
  padding: 15px 0;
  max-height: 200px;
  margin-top: auto;
  width: 100%;
  border-top: ${footerSize.border}px solid rgb(229, 233, 242);
`;

const StyledFooter = styled.div`
  box-sizing: border-box;
  padding: 10px;
  margin: auto;
  width: 100%;
  padding: 0 50px;
  max-width: 1600px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'links store subscribe';

  @media ${device.desktop} {
    padding: 0 20px;
  }

  @media ${device.mobileL} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Links = styled.div`
  grid-area: links;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Subscribe = styled.div`
  grid-area: subscribe;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  color: rgb(31, 45, 61);
  text-decoration: none;
  border-bottom: 3px solid transparent;
`;

const StoreButtonsWrapper = styled.div`
  grid-area: store;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;

  @media ${device.mobileL} {
    text-align: center;
    height: auto;
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <StyledFooter>
        <Links>
          <StyledLink to="/terms-of-service">Terms of Service</StyledLink>
          <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
        </Links>
        <StoreButtonsWrapper>
          <Box>
            <p>Get Started</p>
            <StoreButtons />
          </Box>
        </StoreButtonsWrapper>
        <Subscribe>
          <StyledLink to="/contact-us">contact us</StyledLink>
        </Subscribe>
      </StyledFooter>
    </FooterContainer>
  );
};

export default Footer;
