import { useEffect, useState } from 'react';

const useInfiniteScroll = (
  callback: () => void,
  ref: HTMLDivElement | null,
  disableScroll: boolean,
): [boolean, (v: boolean) => void] => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    let handleScroll: any = null;

    if (ref) {
      handleScroll = () => {
        if (ref.clientHeight >= window.innerHeight + window.scrollY || isFetching || disableScroll)
          return;
        setIsFetching(true);
      };
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ref, isFetching, disableScroll]);

  useEffect(() => {
    if (!isFetching) return;
    callback();
  }, [isFetching]);

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
